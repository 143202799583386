import { createApp } from 'petite-vue';
import '../scss/compare.scss';

const apiUrl = '/assets/components/roevisual/connector.php?action=api/web/compare/';

const productPage = {
    $delimiters: ['<<', '>>'],
    expanded: false,
    visible: false,
    products: [],
    data: { fields: {}, products: [] },
    category: null,
    onlyDiff: false,
    activeSpecs: [],
    activeCompare: [],
    get height() {
        return (this.expanded ? this.products.length * 45 + 100 : 0) + 'px';
    },
    get canCompare() {
        return this.products.length > 1;
    },
    get productsData() {
        if (!this.onlyDiff) {
            return this.data;
        }
        const fields = { ...this.data.fields };
        const products = [...this.data.products];
        Object.keys(fields).forEach((key) => {
            const values = [...new Set(products.map((i) => i[key]))];
            if (values.length === 1) {
                delete fields[key];
            }
        });

        return { products, fields };
    },
    get empty() {
        return !this.products.length;
    },
    init() {
        this.loadState();
        if (document.location.hash.startsWith('#compare/')) {
            this.showModal(document.location.hash.slice(9));
        }
        this.$nextTick(() => {
            this.resizeTables();
            if (this.$refs.specs) {
                this.observeTable(this.$refs.specs);
            }
        });

        window.addEventListener('resize', this.resizeTables);
        window.addEventListener('focus', this.loadState);
        document.addEventListener('keydown', this.onKeydown);
    },
    canBeAdded(category) {
        return !this.category || this.category === category;
    },
    isAdded(id) {
        return this.products.findIndex((i) => i.id === id) > -1;
    },
    addProduct(id, title, category, resource) {
        if (!this.category) {
            this.category = category;
        } else if (category !== this.category) {
            return;
        }
        this.products.push({ id, title, resource });
        /* if (this.products.length === 1) {
            this.expanded = true;
        } */
        this.saveState();
    },
    delProduct(id) {
        this.products = this.products.filter((i) => i.id !== id);
        if (!this.products.length) {
            this.expanded = false;
            this.category = null;
        }
        this.saveState();
    },
    removeAllProducts(e) {
        e.stopPropagation();
        this.products = [];
        this.expanded = false;
        this.category = null;
        this.saveState();
    },
    togglePopup() {
        if (!this.expanded) {
            this.expanded = this.products.length > 0;
        } else {
            this.expanded = false;
        }
        this.saveState();
    },
    saveState() {
        const data = {
            expanded: this.expanded,
            category: this.category,
            products: [...this.products]
        };
        localStorage.setItem('compare-products', JSON.stringify(data));
    },
    loadState() {
        const data = JSON.parse(localStorage.getItem('compare-products') || '{}');
        this.expanded = data.expanded === true && data.products.length > 0;
        this.category = data.category || null;
        this.products = data.products || [];
    },
    async showModal(products = null) {
        if (this.expanded) {
            this.togglePopup();
        }

        try {
            const ids = products && typeof products === 'string'
                ? products
                : this.products.map((i) => i.id).join('-');
            if (!ids) {
                return;
            }
            const response = await fetch(apiUrl + ids);
            if (response.ok) {
                document.location.hash = 'compare/' + ids;
                this.prepareShareLinks(document.location.origin + document.location.pathname, document.location.href);

                this.data = await response.json();
                this.visible = true;
                document.body.classList.add('compare-open');
                this.$nextTick(() => {
                    this.resizeTables();
                    if (this.$refs.compare) {
                        this.observeTable(this.$refs.compare);
                    }
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    },
    hideModal() {
        this.visible = false;
        document.body.classList.remove('compare-open');
        this.prepareShareLinks(document.location.href, document.location.origin + document.location.pathname);
        window.history.replaceState('', document.title, window.location.pathname + window.location.search);
    },
    onDiff({ target }) {
        this.onlyDiff = target.checked;
        this.$nextTick(this.resizeTables);
    },
    onDownload() {
        if (document.location.hash.startsWith('#compare/')) {
            document.location.href = apiUrl + document.location.hash.slice(9) + '/download';
        }
    },
    onKeydown({ key }) {
        if (key === 'Escape') {
            if (this.visible) {
                this.hideModal();
            } else if (this.expanded) {
                this.togglePopup();
            }
        }
    },
    onScrollPrev(ref) {
        const $el = this.$refs[ref];
        if ($el) {
            const children = [...$el.querySelectorAll('.specs-table-item')].reverse();
            const found = this.findNextInactive(children);
            if (found) {
                this.onScrollTo(ref, found);
            }
        }
    },
    onScrollNext(ref) {
        const $el = this.$refs[ref];
        if ($el) {
            const children = [...$el.querySelectorAll('.specs-table-item')];
            const found = this.findNextInactive(children);
            if (found) {
                this.onScrollTo(ref, found);
            }
        }
    },
    findNextInactive(elements) {
        let found = null;
        // eslint-disable-next-line no-restricted-syntax
        for (const elem of elements) {
            if (!elem.classList.contains('active') && !found) {
                found = elem.dataset.product;
            } else if (elem.classList.contains('active')) {
                found = null;
            }
        }
        return found;
    },
    onScrollTo(ref, id) {
        const $el = this.$refs[ref];
        if (!$el) {
            return;
        }

        const parent = $el.querySelector('.specs-table-values');
        const block = $el.querySelector('[data-product="' + id + '"]');
        if (parent && block) {
            // block.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });s
            let offset = block.offsetLeft - parent.offsetLeft;
            if (offset < 0) {
                offset = 0;
            }
            parent.scrollLeft = offset;
        }
    },
    isActive(type, id) {
        if (type === 'compare') {
            return this.activeCompare.includes(id);
        }
        return this.activeSpecs.includes(id);
    },
    resizeTables() {
        [this.$refs.specs, this.$refs.compare].forEach(($el) => {
            if (!$el) {
                return;
            }
            const rows = {};
            const lists = $el.querySelectorAll('.list');
            [...lists].forEach((list) => {
                [...list.querySelectorAll('li')].forEach((row, idx) => {
                    /* eslint-disable no-param-reassign */
                    row.style.height = 'auto';
                    const height = row.offsetHeight;
                    if (!rows[idx]) {
                        rows[idx] = { height, items: [row] };
                    } else {
                        rows[idx].items.push(row);
                        if (rows[idx].height < height) {
                            rows[idx].height = height;
                        }
                    }
                });
            });

            Object.values(rows).forEach(({ height, items }) => {
                items.forEach((item) => {
                    // eslint-disable-next-line no-param-reassign
                    item.style.height = height + 'px';
                });
            });
        });
    },
    observeTable($el) {
        const isCompare = $el.classList.contains('compare-table');

        const options = {
            root: $el,
            // rootMargin: '-50% -50% -50% -50%'
            threshold: 0.5
        };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(({ target, isIntersecting }) => {
                const id = Number(target.dataset.product);
                if (isIntersecting) {
                    target.classList.add('active');
                    if (isCompare) {
                        this.activeCompare.push(id);
                    } else {
                        this.activeSpecs.push(id);
                    }
                } else {
                    target.classList.remove('active');
                    if (isCompare) {
                        this.activeCompare = this.activeCompare.filter((item) => item !== id);
                    } else {
                        this.activeSpecs = this.activeSpecs.filter((item) => item !== id);
                    }
                }
            });
            // console.log(entries, observer);
        }, options);

        [...$el.querySelectorAll('.specs-table-item')].forEach((i) => {
            observer.observe(i);
        });
    },
    prepareShareLinks(origin, replace) {
        const $el = this.$refs.share;
        if (!$el) {
            return;
        }
        const links = $el.querySelectorAll('a');
        const input = $el.querySelector('input');
        if (input) {
            input.value = input.value.replace(origin, replace);
        }
        [...links].forEach((i) => {
            // eslint-disable-next-line no-param-reassign
            i.href = i.href.replace(origin, replace);
        });
    }
};
const specsEl = document.getElementById('specs-block-root');
if (specsEl) {
    createApp(productPage).mount(specsEl);
}
